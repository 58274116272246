import React, { useState } from 'react';
import PropTypes from 'prop-types';
import {
  Button, Col, Container, Form, Row, Spinner,
} from 'react-bootstrap';
import { sendSMSForAppDownload } from 'api/api';
import { validateRegex } from 'app/utilities';
import { logBannerLeadCollection } from 'app/events/Events';
import CustomModal from './CustomModal';
import './homepage/homepage-styles.scss';

const appUrlLink = 'https://llocal.onelink.me/ALsT/olxwo0dd?af_qr=true';
const isMobile = window.screen.width < 992;

const style = {
  wrapper: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
};

const QuickCard = ({
  name, link, image, cardStyle, showPromotionPopup, onHidePromotionPopup,
}) => {
  const [phoneNumber, setPhoneNumber] = useState('');
  const [submitStatus, setSubmitStatus] = useState('');
  const isPhoneDisabled = (
    !phoneNumber
    || phoneNumber.length !== 10
    || submitStatus === 'loading'
  );
  const handleSubmit = () => {
    setSubmitStatus('loading');
    sendSMSForAppDownload(phoneNumber).then(() => {
      setSubmitStatus('success');
      logBannerLeadCollection({
        phoneNumber,
        appUrlLink,
      });
      setTimeout(() => {
        setSubmitStatus('');
        setPhoneNumber('');
        onHidePromotionPopup();
        if (isMobile) {
          window.open(appUrlLink, '_blank');
        }
      }, 2000);
    }).catch(() => {
      setSubmitStatus('error');
    });
  };
  return (
    <>
      <CustomModal
        show={showPromotionPopup}
        onHide={() => { onHidePromotionPopup(); }}
        size="lg"
        backdrop
        body={(
          <Container className="download-app-container border-radius-16">
            <Row className="flex-md-row flex-column">
              {/* Left Section - Scan QR */}
              <Col md={10} className="d-flex align-items-center justify-content-center">
                <div className="scan-section">
                  <h2 className="font-weight-black mt-3">Scan to download the LoveLocal app</h2>
                  <div className="qr-placeholder pt-3">
                    {/* Replace with actual QR component or image */}
                    <img src="/images/qr-code-image.png" alt="QR Code" width="100%" height="100%" />
                    {/* <div className="qr-image">Scan QR Code Here</div> */}
                  </div>
                </div>
              </Col>

              <Col md={2} className="d-flex align-items-center justify-content-center p-0">
                <div className="border border-gray border-end border-end-1 border-height" />
                <div className="or-position font-weight-black fs-1">OR</div>
              </Col>

              {/* Right Section - Phone Input */}
              <Col md={10} className="d-flex align-items-center justify-content-center">
                <div className="phone-section">
                  <h2 className="font-weight-black mb-4">Get the LoveLocal app download link on SMS</h2>
                  <Form>
                    <Form.Group controlId="phoneNumber">
                      <Form.Label>Enter your phone number</Form.Label>
                      <Form.Control
                        type="tel"
                        className="bg-white"
                        placeholder="10 digit phone number"
                        pattern="[0-9]{10}"
                        minLength={10}
                        maxLength={10}
                        required
                        value={phoneNumber}
                        onChange={(e) => {
                          if (!validateRegex('phoneNumber', e.target.value)) {
                            return;
                          }
                          setPhoneNumber(e.target.value);
                        }}
                      />
                    </Form.Group>
                    <Button
                      color="primary"
                      className={`w-100 ${isPhoneDisabled ? 'bg-disabled' : 'bg-primary'} d-flex align-items-center justify-content-center`}
                      onClick={() => {
                        handleSubmit();
                      }}
                      disabled={isPhoneDisabled}
                    >
                      {
                        submitStatus === 'loading' && (
                          <Spinner animation="border" size="sm" />
                        )
                      }
                      {'  '}
                      <b>
                        Get download link on SMS
                      </b>
                      {'  '}
                      <div>
                        <svg width="25" height="24" viewBox="0 0 25 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                          <path fillRule="evenodd" clipRule="evenodd" d="M12.5 22C18.0228 22 22.5 17.5228 22.5 12C22.5 6.47715 18.0228 2 12.5 2C6.97715 2 2.5 6.47715 2.5 12C2.5 17.5228 6.97715 22 12.5 22ZM14.0657 7.93432C13.7533 7.6219 13.2467 7.6219 12.9343 7.93432C12.6219 8.24673 12.6219 8.75327 12.9343 9.06569L15.0686 11.2H8.00001C7.55818 11.2 7.20001 11.5582 7.20001 12C7.20001 12.4418 7.55818 12.8 8.00001 12.8H15.0686L12.9343 14.9343C12.6219 15.2467 12.6219 15.7533 12.9343 16.0657C13.2467 16.3781 13.7533 16.3781 14.0657 16.0657L17.5657 12.5657C17.8781 12.2533 17.8781 11.7467 17.5657 11.4343L14.0657 7.93432Z" fill="white" />
                        </svg>
                      </div>
                    </Button>
                  </Form>
                  {
                    submitStatus === 'success' && (
                      <div className="pt-3 text-green fs-6 font-weight-bold">
                        Thanks! Check your SMS inbox for the LoveLocal App download link.
                      </div>
                    )
                  }
                  {
                    submitStatus === 'error' && (
                      <div className="pt-3 text-red fs-6 font-weight-bold">
                        Oops! something went wrong. Please retry.
                      </div>
                    )
                  }
                </div>
              </Col>
            </Row>
          </Container>
        )}
      />
      {
      (image)
      && (
      <div style={style.wrapper}>
        {
          link ? (
            <a href={link} style={cardStyle}>
              <img className="w-100 h-100" src={image} alt={name} />
            </a>
          ) : (
            <div style={cardStyle}>
              <img className="w-100 h-100" src={image} alt={name} />
            </div>
          )
        }
      </div>
      )
    }
    </>
  );
};

QuickCard.propTypes = {
  name: PropTypes.string,
  link: PropTypes.string,
  image: PropTypes.string,
  cardStyle: PropTypes.shape({}),
  showPromotionPopup: PropTypes.bool,
  onHidePromotionPopup: PropTypes.func,
};

QuickCard.defaultProps = {
  name: '',
  link: null,
  image: '',
  cardStyle: {},
  showPromotionPopup: false,
  onHidePromotionPopup: () => {},
};

export default QuickCard;
