import { getUUID } from '../app/utilities/Storage';
import { apiUrls } from './utils';

const {
  legacyBaseUrl, OOBaseUrl, newOOBaseUrl, imageBaseUrl,
} = apiUrls();

const djangoauxiliaryV1 = 'djangoauxiliary/v1';
const capiV1 = 'capi/v1';
const capiV2 = 'capi/v2';
const homeDeliveryV1 = 'homedelivery/v1';
const eventserviceV1 = 'eventservice/v1';

export const urlConfig = {
  uuid: getUUID(),
  baseUrl: OOBaseUrl,
  newOOBaseUrl,
  djangoauxiliaryV1,
  capiV1,
  capiV2,
  homeDeliveryV1,
  eventserviceV1,
};

export const userExistance = (phoneNumber) => `${legacyBaseUrl}${djangoauxiliaryV1}/member/${phoneNumber}`;

export const otp = () => `${newOOBaseUrl}auth/send-otp`;

export const signUp = () => `${newOOBaseUrl}auth/login`;

export const referralCode = (customerId) => `${newOOBaseUrl}customer/referral-code/${customerId || ''}`;

export const refreshToken = () => `${newOOBaseUrl}auth/refresh-token`;

export const brandBanners = () => `${newOOBaseUrl}customer/home/banners/brand`;

export const loginWithOtp = () => `${legacyBaseUrl}${capiV2}/members/auth/password/forgot/`;

export const profile = () => `${legacyBaseUrl}${capiV2}/members/${getUUID()}/`;

export const cart = (cartId) => `${OOBaseUrl}${homeDeliveryV1}/cart/${cartId || ''}`;

export const coupon = () => `${OOBaseUrl}${homeDeliveryV1}/coupon/`;

export const customerAddress = (addressId) => `${OOBaseUrl}${homeDeliveryV1}/customerlocation/${addressId || ''}`;

export const customerStoreLike = () => `${OOBaseUrl}${homeDeliveryV1}/customerstorelike/`;

export const favouriteshops = (customerId) => `${OOBaseUrl}${homeDeliveryV1}/favouriteshops/${customerId}`;

export const globalSearch = () => `${OOBaseUrl}${homeDeliveryV1}/fullproductsearch/`;

export const order = (orderId) => `${OOBaseUrl}${homeDeliveryV1}/order/${orderId || ''}`;

export const onlineProducts = (productId) => `${OOBaseUrl}${homeDeliveryV1}/onlineorderstoreproductvariants/${productId || ''}`;

export const categoryProductSearch = (productId) => `${OOBaseUrl}${homeDeliveryV1}/categoryproductsearch/${productId || ''}`;

export const previousOrderProducts = () => `${OOBaseUrl}${homeDeliveryV1}/previousorderproduct/`;

export const storeWisePreviousOrderProducts = () => `${OOBaseUrl}${homeDeliveryV1}/onlineorderstorepreviusorderproductlisting/`;

export const popularProducts = (productId) => `${OOBaseUrl}${homeDeliveryV1}/popularproducts/${productId || ''}`;

export const pastProducts = () => `${OOBaseUrl}${homeDeliveryV1}/previousproduct/`;

export const productCategories = () => `${OOBaseUrl}${homeDeliveryV1}/storecategory/`;

export const storeCategories = () => `${OOBaseUrl}${homeDeliveryV1}/reststoretype/`;

export const specialOffers = () => `${OOBaseUrl}${homeDeliveryV1}/specialoffers/`;

export const cartAvailableOffers = (cartId) => `${OOBaseUrl}${homeDeliveryV1}/cartavailableoffers/${cartId || ''}`;

export const storeAuxiliary = (storeId) => `${OOBaseUrl}${homeDeliveryV1}/storeauxiliary/${storeId}`;

export const stores = () => `${OOBaseUrl}${homeDeliveryV1}/onlineorderstorelist/`;

export const onlineStores = () => `${OOBaseUrl}${homeDeliveryV1}/onlineorderstorelistingwithmatrix/`;

export const seoMetadata = () => `${OOBaseUrl}${homeDeliveryV1}/seopagemetadata/`;

export const storeLike = () => `${OOBaseUrl}${homeDeliveryV1}/customerstorelike/`;

export const reverseGeoCode = (latlng) => `https://maps.googleapis.com/maps/api/geocode/json
?latlng=${latlng}&key=AIzaSyBX95UP_7JsSTKZavZEX-RWnliA3fAwI0Q`;

export const staticGoogleMap = (size, zoom, latlng) => `https://maps.googleapis.com/maps/api/staticmap?
size=${size}&zoom=${zoom}&markers=color:0xb8238e|${latlng}&mapTypeId=terrain&key=AIzaSyDBqgtvSx2QNQApIvtE4Ev9EUh0OXMg-A0`;

export const googleDirection = (origin, destination) => `https://www.google.com/maps/dir/?api=1&origin=${origin}&destination=${destination}`;

export const offers = () => `${OOBaseUrl}${homeDeliveryV1}/productoffer/`;

export const banners = () => `${OOBaseUrl}${homeDeliveryV1}/banners/`;

export const razorpayOrder = () => `${OOBaseUrl}${homeDeliveryV1}/razorpay/`;

export const razorpayOrderStatus = (paymentId) => `${OOBaseUrl}${homeDeliveryV1}/orderstatus/${paymentId}`;

export const customerStoreReview = (orderId) => `${newOOBaseUrl}/order/${orderId}/rating/add`;

export const notifications = () => `${OOBaseUrl}${homeDeliveryV1}/b2cnotifications/`;

export const truecaller = (requestId) => `${OOBaseUrl}${homeDeliveryV1}/truecallercallback/${requestId || ''}`;

export const eventTracker = () => `${OOBaseUrl}${homeDeliveryV1}/eventtracker/`;

export const rewards = (scratchcardId) => `${OOBaseUrl}${homeDeliveryV1}/scratchcard/${scratchcardId || ''}`;

export const referEarnCustomerRegistered = () => `${OOBaseUrl}${homeDeliveryV1}/appreferandearn/?checkIsCustomerRegistered=true`;

export const ordertatbreached = (customerId) => `${OOBaseUrl}${homeDeliveryV1}/ordertatbreached/${customerId}`;

export const webEvents = () => `${OOBaseUrl}${eventserviceV1}/userevent/`;

export const storesSpecificCoupons = () => `${OOBaseUrl}${homeDeliveryV1}/couponspecificstores/`;

export const unusedOffersCount = () => `${OOBaseUrl}${homeDeliveryV1}/unscratchedunusedoffer/${getUUID()}`;

export const appExOffer = () => `${OOBaseUrl}${homeDeliveryV1}/appexoffers/`;

export const synctohomedelivery = (customerId) => `${OOBaseUrl}${homeDeliveryV1}/synctohomedelivery/${customerId}/`;

export const topSavingOffers = () => `${OOBaseUrl}${homeDeliveryV1}/topsavingofferproduct/`;

export const topPicksShops = () => `${OOBaseUrl}${homeDeliveryV1}/toppickshops/`;

export const deviceInfo = (customerId) => `${OOBaseUrl}${homeDeliveryV1}/customer/${customerId}`;

export const newlyAddedStores = () => `${OOBaseUrl}${homeDeliveryV1}/newonboardedonlineorderstorelist/`;

export const premiumStores = () => `${OOBaseUrl}${homeDeliveryV1}/storesearch/`;

export const customerStat = (uid) => `${OOBaseUrl}${homeDeliveryV1}/curtomerbasestat/${uid || ''}`;

export const btocfaqaggregated = () => `${OOBaseUrl}${homeDeliveryV1}/btocfaqaggregated/`;

export const faqs = () => `${OOBaseUrl}${homeDeliveryV1}/btocfaq/`;

export const faqHelpUpdate = () => `${OOBaseUrl}${homeDeliveryV1}/btocfaqhelpupdate/`;

export const storeReviewSummary = (storeCode) => `${OOBaseUrl}${homeDeliveryV1}/storereviewsummary/${storeCode || ''}`;

export const popularCityAndLocality = () => `${OOBaseUrl}${homeDeliveryV1}/popularcitiesandlocalities/`;

export const bulkCustomerLocation = () => `${OOBaseUrl}${homeDeliveryV1}/bulkcustomerlocation/`;

export const bulkCart = (cartId) => `${OOBaseUrl}${homeDeliveryV1}/bulkcart/${cartId || ''}`;

export const orderLocation = (cartId) => `${OOBaseUrl}${homeDeliveryV1}/orderlocation/${cartId || ''}`;

export const openPositions = () => `${OOBaseUrl}aboutus/openpositions/`;

export const globalSearchSuggestion = () => `${OOBaseUrl}${homeDeliveryV1}/searchsuggestion/`;

export const globalProductSearch = () => `${OOBaseUrl}${homeDeliveryV1}/productsearch/`;

export const globalStoreProductDetails = (productId) => `${OOBaseUrl}${homeDeliveryV1}/storeproductdetail/${productId || ''}`;

export const storeProducts = (productId) => `${OOBaseUrl}${homeDeliveryV1}/storeproductvariants/${productId || ''}`;

export const globalSearchBogoOffer = () => `${OOBaseUrl}${homeDeliveryV1}/bogooffer/`;

export const webEnquiry = () => `${OOBaseUrl}${homeDeliveryV1}/webenquiry`;

export const applyValueOffer = () => `${OOBaseUrl}${homeDeliveryV1}/applyvalueoffer/`;

export const customerStoreReferral = () => `${OOBaseUrl}${homeDeliveryV1}/customerstorereferral/`;

export const customProductSection = () => `${OOBaseUrl}${homeDeliveryV1}/customproductsection/`;

export const customProductDetail = () => `${OOBaseUrl}${homeDeliveryV1}/customproductdetail/`;

export const imageUpload = () => `${imageBaseUrl}/auxiliaryservice/v1/imageupload/`;

export const inFocusToday = () => `${newOOBaseUrl}/customer/brand-products`;

export const mustTrySearch = () => `${newOOBaseUrl}/search/product`;

export const maxSavingsProducts = () => `${OOBaseUrl}${homeDeliveryV1}/topsavingofferproduct/`;

export const inFocusTodayProducts = () => `${newOOBaseUrl}/customer/brand-products`;

export const productSearch = () => `${newOOBaseUrl}search/product`;

export const inStoreProductSearch = (storeId) => `${newOOBaseUrl}/search/in-store/${storeId || ''}/product`;

export const storeSearch = () => `${OOBaseUrl}${homeDeliveryV1}/storesearch/`;

export const recentSearch = () => `${newOOBaseUrl}search/common`;

export const newGlobalSearch = (path) => `${newOOBaseUrl}search/${path || ''}`;

export const createSearchHistory = () => `${newOOBaseUrl}customer/search/add`;

export const storeNewCategory = () => `${OOBaseUrl}${homeDeliveryV1}/storegroup/`;

export const monthlySamaan = () => `${newOOBaseUrl}customer/monthly-items`;

export const servicableStoreCountApi = () => `${newOOBaseUrl}store/count`;

export const recommendedProducts = () => `${newOOBaseUrl}search/recommendedproducts`;

export const relatedProducts = () => `${newOOBaseUrl}search/store/related-products`;

export const premiumStoresSOA = () => `${newOOBaseUrl}customer/home/stores`;

export const notifyOOSProducts = (productId) => `${newOOBaseUrl}product/notify/${productId}`;

export const sendSMSForAppDownload = () => `${newOOBaseUrl}customer/send-sms-for-app-download`;
